<template>
  <div class="bg-gray-4 h-100vh d-flex justify-content-center">
    <div class="shipment-card">
      <div class="p-3">
        <slot name="title"></slot>
        <div v-if="isLoading">
          <OtherLoading variant="warning" />
        </div>
        <div v-else>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: { OtherLoading },
  name:'BookingCard',
  props: {
      isLoading: Boolean
  }
}
</script>

<style lang="scss" scoped>
.shipment-card {
  width: 100%;
  margin-top: 1rem;
  box-shadow: none !important;
}

@media only screen and (min-width: 767.98px) {
  .shipment-card {
    max-width: 800px;
    margin-top: 1rem;
  }
}
</style>
